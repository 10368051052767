<template>
  <div>
    <vs-button
      icon="add"
      @click="openModal()"
      color="success"
      :disabled="!$atividades.permissoes.includes(5)"
      type="line"
      size="small"
      ><b style="font-size: 16px;">Incluir</b></vs-button
    >
    <vs-popup v-if="open" title="Cadastrar Novo Movimento" :active.sync="modalCadastrar">
      <vs-row vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
        <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
          <Credito @update="close()"></Credito>
        </vs-col>
        <vs-col vs-w="6" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
          <Debito @update="close()"></Debito>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import Credito from "./cadastro_credito";
import Debito from "./cadastro_debito";
export default {
  data() {
    return {
      modalCadastrar: false,
      open: false
    };
  },
  methods: {
    async close() {
      this.modalCadastrar = await false
      await this.$emit("update");
    },
    async openModal() {
      this.open = await true
      this.modalCadastrar = await true;
    }
  },
  mounted() {},
  components: {
    ...components,
    Credito,
    Debito
  }
};
</script>
