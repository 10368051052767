<template>
  <div>
    <div id="debito_credito" class="cardTable mb-1 vs-con-loading__container">
      <div
        class="mt-1"
        v-if="debito_credito.length == 0 || debito_credito.length == undefined"
      >
        <div class="pt-20 scroll-debito-credito con-colors">
          <ul class="pt-20">
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">
                Nenhum Movimento Financeiro
              </h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="scroll-debito-credito">
        <b-table-simple sticky-header="80vh" striped responsive>
          <b-thead>
            <b-tr>
              <b-th style="width: 5%" class="text-center">Nota</b-th>
              <b-th style="width: 5%" class="text-center">Emissão</b-th>
              <b-th style="width: 10%" class="text-center"
                >Cliente/Fornecedor</b-th
              >
              <b-th style="width: 10%" class="text-center">Empresa</b-th>
              <b-th style="width: 10%" class="text-center">Portador</b-th>
              <b-th style="width: 8%" class="text-center">V. Pago</b-th>
              <b-th style="width: 12%" class="text-center">Plano Conta</b-th>
              <b-th style="width: 12%" class="text-center">Descrição</b-th>
              <b-th style="width: 10%" class="text-center">Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              :style="
                conta.tipo_movimento == 1
                  ? 'color:red ;background-color: #fce3e3 !important'
                  : conta.tipo_movimento == 2
                  ? 'color: green;background-color:#e8fce8 !important;'
                  : ''
              "
              :key="index"
              v-for="(conta, index) in debito_credito"
            >
              <b-td style="font-size: 12px" class="text-center font-bold">{{
                conta.nota > 0
                  ? conta.nota + (conta.parcela ? "." + conta.parcela : "")
                  : "..."
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center">{{
                conta.emissao ? conta.emissao : "..."
              }}</b-td>
              <b-td v-if="conta.cliente">
                <vs-button
                  color="dark"
                  class="py-0 px-0 my-0 pr-1"
                  size="small"
                  type="line"
                  @click="
                    renderComponent('VisualizarCliente', conta.id_cliente)
                  "
                >
                  <span class="nome-cliente-small">{{
                    conta.cliente ? conta.cliente : "..."
                  }}</span>
                </vs-button></b-td
              >
              <b-td v-else style="font-size: 12px" class="text-center">{{
                conta.fornecedor ? conta.fornecedor : "..."
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center">{{
                conta.empresa ? conta.empresa : "..."
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center">{{
                conta.portador ? conta.portador : "..."
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center font-bold">{{
                $currency(conta.valor)
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center">{{
                conta.plano_conta ? conta.plano_conta : "..."
              }}</b-td>
              <b-td style="font-size: 12px" class="text-center">{{
                conta.obs ? conta.obs : "..."
              }}</b-td>
              <b-td style="font-size: 12px">
                <div class="w-full">
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-button
                        icon="edit"
                        @click="renderComponent('Editar', conta)"
                        size="small"
                        :disabled="
                          !$atividades.permissoes.includes(5) ||
                            conta.id_status == 4 ||
                            conta.id_status == 5
                        "
                        color="primary"
                      />
                    </vs-col>
                    <vs-col
                      vs-w="6"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <el-popconfirm
                        confirmButtonText="Confirmar"
                        cancelButtonText="Cancelar"
                        icon="el-icon-info"
                        @confirm="deletar(conta)"
                        iconColor="red"
                        title="Deseja deletar este movimento financeiro?"
                      >
                        <vs-button
                          slot="reference"
                          :disabled="
                            !$atividades.permissoes.includes(6) ||
                              buttonDisabled
                          "
                          icon="close"
                          size="small"
                          color="danger"
                        />
                      </el-popconfirm>
                    </vs-col>
                  </vs-row>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>
    <vx-card no-shadow card-background="dark">
      <vs-row
        vs-type="flex"
        class
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="1"
          class
        >
          <vs-button
            @click="voltar()"
            icon-pack="feather"
            line-origin="left"
            color="#fff"
            type="line"
            icon="icon-arrow-left"
            size="small"
          >
            <span style="font-size: 14px">Voltar</span>
          </vs-button>
        </vs-col>
        <vs-col
          vs-w="6"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
        >
          <span style="color: white; font-size: 11px" class="ml-6 pt-3"
            >Contas: {{ debito_credito.length }}</span
          >
          <span style="color: white; font-size: 11px" class="ml-6 pt-3">
            Saldo Inicial:
            {{ $currency(saldos.saldo_inicial) }}
          </span>
          <span style="color: white; font-size: 11px" class="ml-6 pt-3">
            Saldo Atual:
            {{ $currency(saldos.saldo_atual) }}
          </span>
          <span style="color: white; font-size: 11px" class="ml-6 pt-3">
            Total:
            {{
              $currency(
                parseFloat(saldos.saldo_atual) -
                  parseFloat(saldos.saldo_inicial)
              )
            }}
          </span>

          <span style="color: white; font-size: 11px" class="ml-6 pt-3">
            Entradas:
            {{ $currency(parseFloat(totalEntrada)) }}
          </span>

          <span style="color: white; font-size: 11px" class="ml-6 pt-3">
            Saidas:
            {{ $currency(parseFloat(totalSaida)) }}
          </span>
        </vs-col>
        <vs-col
          vs-w="5"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
        >
          <vs-col vs-w="2.7" vs-justify="flex-end" vs-align="flex-end">
            <portadoresSaldos
              @atualizarSaldos="getDebitoCredito"
              :saldosPortadores="saldosPortadores"
            ></portadoresSaldos>
          </vs-col>
          <vs-col vs-w="2.7" vs-justify="flex-end" vs-align="flex-end">
            <Cadastrar @update="getDebitoCredito" />
          </vs-col>
          <vs-col vs-w="2.7" vs-justify="flex-end" vs-align="flex-end">
            <Filtro @filtro="Filtrar" />
          </vs-col>
          <vs-col vs-w="2.7" vs-justify="flex-end" vs-align="flex-end">
            <ExcelCaixa
              @noData="noData()"
              :portadores="saldosPortadores"
              :filtro="filtro"
              :contas="debito_credito"
            />
          </vs-col>
        </vs-col>
      </vs-row>
    </vx-card>

    <component
      v-bind:is="buttonComponent"
      :conta="selectedItem"
      :id_cliente="selectedItem"
      :key="reRenderButtonComponent"
      @update="getDebitoCredito"
    />
  </div>
</template>
<script>
import Filtro from "./components/filtro";
import Cadastrar from "./components/cadastrar";
import Editar from "./components/editar";
import ExcelCaixa from "./components/excelCaixa";
import portadoresSaldos from "./components/portadoresSaldos";
import components from "@/components/default/exports.js";
import VisualizarCliente from "./../../../cliente/visualizarSemBotao.vue";
export default {
  data() {
    return {
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 0.5,
        height: 1000
      },
      filtro: {
        dataHoje: this.$formartData.dataFiltro()
      },
      empresas: [],
      plano_conta: [],
      portadores: [],
      debito_credito: [],
      saldos: 0,
      saldosPortadores: {},
      buttonDisabled: false,
      buttonComponent: "",
      reRenderButtonComponent: 0,
      selectedItem: {},
      totalEntrada: 0,
      totalSaida: 0
    };
  },
  methods: {
    async getDebitoCredito() {
      await this.$vs.loading({ container: "#debito_credito", scale: 0.6 });
      try {
        const res = await this.$http.post(
          `getMovimentoFinanceiros`,
          this.filtro
        );
        this.debito_credito = await res.debito_credito;
        this.saldos = await res.saldos;
        this.saldosPortadores = await res.saldosPortadores;
        if (res.debito_credito.length > 0) {
          this.totalEntrada = await res.debito_credito.reduce((sum, obj) => {
            return obj.tipo_movimento == 2
              ? parseFloat(sum) + parseFloat(obj.valor)
              : parseFloat(sum);
          }, 0);
          this.totalSaida = await res.debito_credito.reduce((sum, obj) => {
            return obj.tipo_movimento == 1
              ? parseFloat(sum) + parseFloat(obj.valor)
              : parseFloat(sum);
          }, 0);
        }
      } catch (err) {
        console.log(err);
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#debito_credito > .con-vs-loading");
    },
    async voltar() {
      this.$router.push("/financeiro");
    },
    async Filtrar(filtro) {
      this.filtro = await filtro;
      await this.getDebitoCredito();
    },
    async deletar(movimento) {
      this.buttonDisabled = true;
      try {
        await this.$http.put(`deletarMovimentoConta/` + movimento.id, {
          lixeira: 1,
          movimento
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "excluir",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto: "Exclusão do Movimento Financeiro N°" + movimento.id
        };
        await this.$logger(logData);
        await this.getDebitoCredito();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.buttonDisabled = false;
      }
    },
    async noData() {
      this.$vs.notify({
        title: "Selecione um portador!",
        text: "Use o Filtro para selecionar um portador",
        color: "danger"
      });
    },
    async renderComponent(component2Render, item) {
      this.buttonComponent = component2Render;
      this.selectedItem = item;
      this.reRenderButtonComponent++;
    }
  },
  async mounted() {
    await this.getDebitoCredito();
  },
  components: {
    Filtro,
    ...components,
    Cadastrar,
    Editar,
    VisualizarCliente,
    portadoresSaldos,
    ExcelCaixa
  }
};
</script>
<style lang="scss">
.scroll-debito-credito {
  height: 82vh !important;
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
#color.colorGreen {
  color: green !important;
  background-color: #e8fce8 !important;
}
#color.colorRed {
  color: #dd3522 !important;
  background-color: #f5efef !important;
}
.el-popconfirm__main {
  font-size: 12px !important;
}
</style>
