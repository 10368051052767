<template>
  <div>
    <vs-button
      icon="loupe"
      color="#3da4fd"
      type="line"
      @click="openModal()"
      size="small"
      ><b style="font-size: 16px">Saldos</b></vs-button
    >
    <vs-popup
      v-if="open"
      title="Saldos dos Portadores"
      :active.sync="modalPortadores"
    >
      <div id="portadoresSaldos" class="mb-1 vs-con-loading__container">
        <div v-if="saldosPortadores.length == 0">
          <div class="pt-20 scroll-portadores-saldos con-colors">
            <ul class="pt-20">
              <li class="danger-box">
                <h2 class="p-5" style="color: white !important">
                  Nenhum Portador
                </h2>
              </li>
            </ul>
          </div>
        </div>
        <div v-else class="w-full mt-1">
          <vs-row
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="12"
            >
              <div class="w-full">
                <VuePerfectScrollbar
                  class="scroll-portadores-saldos"
                  :settings="settings"
                >
                  <vs-table
                    class="px-2"
                    stripe
                    :data="saldosPortadores"
                    max-items="100"
                    id="tableCliente"
                  >
                    <template slot="thead">
                      <vs-th sort-key="numero" style="width: 15%"
                        >Portador</vs-th
                      >
                      <vs-th
                        sort-key="saldo_inicial"
                        style="width: 15%"
                        id="center"
                        >Saldo Inicial</vs-th
                      >
                      <vs-th
                        sort-key="saldo_atual"
                        style="width: 15%"
                        id="center"
                        >Saldo Atual</vs-th
                      >
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr :key="index" v-for="(tr, index) in data">
                        <vs-td :data="tr.id" style="font-size: 12px">
                          <b>{{ tr.nome }} </b>
                        </vs-td>
                        <vs-td
                          :data="tr.saldo_inicial"
                          class="text-center"
                          style="font-size: 12px"
                        >
                          <b> {{ $currency(tr.saldo_inicial) }}</b>
                        </vs-td>
                        <vs-td
                          :data="tr.saldo_atual"
                          class="text-center"
                          style="font-size: 12px"
                        >
                          <b> {{ $currency(tr.saldo_atual) }}</b>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </VuePerfectScrollbar>
              </div>
            </vs-col>
          </vs-row>
          <vs-row
            vs-type="flex"
            class="mb-2"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="4"
            >
              <vs-button
                icon="swap_horiz"
                color="success"
                type="relief"
                size="small"
                @click="openModalAjustarSaldoPortador()"
                ><b style="font-size: 13px">Ajustar Saldos</b></vs-button
              >
            </vs-col>
          </vs-row>
        </div>
      </div>
    </vs-popup>
    <component
      @atualizarSaldos="atualizarSaldos"
      v-bind:is="componentModal"
      :key="reRenderComponentModal"
    />
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import ajustarSaldoPortador from "./ajustarSaldoPortador"
export default {
  props: {
    saldosPortadores: {},
  },
  data () {
    return {
      open: false,
      modalPortadores: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      componentModal: "",
      reRenderComponentModal: 1
    }
  },
  methods: {
    async openModal () {
      this.open = true
      this.modalPortadores = true
    },
    async openModalAjustarSaldoPortador () {
      this.modalPortadores = false
      this.componentModal = "ajustarSaldoPortador"
      this.reRenderComponentModal++
    },
    async atualizarSaldos () {
      this.$emit("atualizarSaldos")
    }
  },
  components: {
    ...components,
    ajustarSaldoPortador
  }
};
</script>
<style lang="scss">
.scroll-portadores-saldos {
  height: 55vh;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
