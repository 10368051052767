<template>
  <div class="w-full">
    <vx-card class="cadastro-debito overflow-hidden" @click="openModal()">
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="p-5">
              <span>
                <vs-icon icon="receipt" size="35px" color="#C0564B" class="img"></vs-icon>
              </span>
            </vs-col>
            <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center" class="pb-5">
              <span style="font-size: 20px">Débito</span>
            </vs-col>
          </div>
        </div>
      </template>
    </vx-card>
    <vs-popup title="Cadastrar Movimento de Débito" :active.sync="modalCadastrar">
      <div class="w-full vs-con-loading__container" id="loadingCadDebito">
        <div class="w-full vs-con-loading__container" id="loadingSelectsCadD">
          <vs-row vs-type="flex" class="mb-2" vs-justify="center" vs-align="center" vs-w="12">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="12">
              <div class="w-full">
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Fornecedor</label>
                <br />
                <el-select
                  filterable
                  name="fornecedor"
                  clearable
                  style="width: 270px"
                  remote
                  :remote-method="filtroFornecedor"
                  loading-text="Buscando..."
                  :loading="loading"
                  placeholder="Pesquise"
                  v-model="conta.id_fornecedor"
                  class="mx-1 mt-2"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="(fornecedor, index) in fornecedores"
                    :value="fornecedor.id"
                    :label="fornecedor.nome_fantasia"
                    :key="index"
                  ></el-option>
                </el-select>
              </div>
              <div class="w-full">
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Empresa</label>
                <br />
                <el-select
                  filterable
                  name="empresa"
                  clearable
                  style="width: 270px"
                  v-model="conta.id_empresa"
                  :popper-append-to-body="false"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="empresa in empresas"
                    :value="empresa.id"
                    :label="empresa.razao_social"
                    :key="empresa.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
          <vs-row vs-type="flex" class="mb-2" vs-justify="center" vs-align="center" vs-w="12">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="12">
              <div class="w-full">
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Plano Conta</label>
                <br />
                <el-select
                  filterable
                  clearable
                  remote
                  :remote-method="filtroPlanoConta"
                  :loading="loading"
                  loading-text="Buscando..."
                  name="plano_conta"
                  placeholder="Pesquise"
                  :popper-append-to-body="false"
                  v-model="conta.id_plano_conta"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="data in plano_conta"
                    :value="data.id"
                    :label="data.nome"
                    :key="data.id"
                  ></el-option>
                </el-select>
              </div>
              <div class="w-full">
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Portador</label>
                <br />
                <el-select
                  filterable
                  clearable
                  name="portador"
                  :popper-append-to-body="false"
                  v-model="conta.id_portador"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="data in portadores"
                    :value="data.id"
                    :label="data.nome"
                    :key="data.id"
                  ></el-option>
                </el-select>
              </div>
              <div class="w-full">
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3">Forma de Pagamento</label>
                <br />
                <el-select
                  filterable
                  name="formas_pagamento"
                  clearable
                  :popper-append-to-body="false"
                  v-model="conta.id_forma_pagamento"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="forma in formas_pagamento"
                    :value="forma.id"
                    :label="forma.nome"
                    :key="forma.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
        </div>
        <vs-row vs-type="flex" class="mb-2" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="12">
            <div class="w-full">
              <vs-input
                style="width: 270px"
                color="dark"
                class="mx-2"
                name="data"
                type="date"
                label="Data"
                v-model="conta.data"
              ></vs-input>
              <vs-row>
                <span
                  class="text-danger mx-2"
                  style="font-size: 11px"
                  v-show="errors.has('data')"
                >{{ $validators.empty }}</span>
              </vs-row>
            </div>
            <div class="w-full">
              <vs-input
                color="dark"
                class="mx-1"
                style="width: 270px"
                name="valor"
                type="text"
                v-currency="$currencyFormatterOptions"
                placeholder="R$ 0,00"
                label="Valor"
                v-model="conta.valor"
              ></vs-input>
              <vs-row>
                <span
                  class="text-danger mx-2"
                  style="font-size: 11px"
                  v-show="errors.has('valor')"
                >{{ $validators.empty }}</span>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" class="mb-2" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="12">
            <vs-input
              color="dark"
              class="mx-1 w-fulll"
              style="width: 600px"
              type="text"
              label="Observação"
              v-model="conta.obs"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-w="12">
            <vs-button
              @click="submitForm()"
              class="w-full mt-3"
              icon="add"
              color="danger"
              type="relief"
            >Incluir</vs-button>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      conta: {},
      empresas: {},
      plano_conta: {},
      fornecedores: {},
      portadores: {},
      formas_pagamento: {},
      loading: false,
      modalCadastrar: false
    };
  },
  methods: {
    async openModal() {
      this.modalCadastrar = await true;
      try {
        await this.$vs.loading({
          container: "#loadingSelectsCadD",
          scale: 0.6
        });
        await this.getPortadores();
        await this.getEmpresas();
        await this.getFormasPagamento();
        this.conta.data = await this.$formartData.dataFiltro();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#loadingSelectsCadD > .con-vs-loading");
      }
    },
    async submitForm() {
      if (
        this.conta.id_fornecedor != null &&
        this.conta.id_portador != null &&
        this.conta.id_forma_pagamento != null &&
        this.conta.id_empresa != null &&
        this.conta.id_plano_conta != null &&
        this.conta.data != null &&
        this.conta.valor != null
      ) {
        await this.Cadastrar();
      } else {
        this.$vs.notify({
          title: "Preencha os campos obrigatorios",
          text:
            "Fornecedor <br> Empresa <br> Plano de Conta <br> Valor <br> Portador <br> Forma de Pagamento <br> Data <br>Valor",
          color: "danger"
        });
      }
    },
    async Cadastrar() {
      await this.$vs.loading({ container: "#loadingCadDebito", scale: 0.6 });
      try {
        let valor = "" + this.conta.valor;
        if (valor && valor.search(",") !== -1) {
          valor = valor.replace(/\./g, "");
          valor = valor.replace(/,/g, ".");
        }
        this.conta.valor = valor;

        this.conta.tipo_movimento = 1;
        const res = await this.$http.post("movimento_financeiro", this.conta);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "cadastrar",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto: "Cadastro do Movimento Financeiro de Débito N°" + res.id
        };
        await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$emit("update");
        this.modalCadastrar = await false;
        this.conta = await {};
        await this.$vs.loading.close("#loadingCadDebito > .con-vs-loading");
      }
    },
    async filtroFornecedor(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getFornecedorByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async filtroPlanoConta(query) {
      if (query.length > 2) {
        this.loading = await true;
        try {
          await this.getPlanoContaByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = await false;
        }
      }
    },
    async getPlanoContaByQuery(query) {
      try {
        this.plano_conta = await this.$http.post(`getPlanoContaByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getFornecedorByQuery(query) {
      try {
        this.fornecedores = await this.$http.post(`getFornecedorByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getFormasPagamento() {
      try {
        this.formas_pagamento = await this.$http.get(`formasPagamento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPortadores() {
      try {
        this.portadores = await this.$http.get(`portador`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getEmpresas() {
      try {
        this.empresas = await this.$http.get(`empresa`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    }
  }
};
</script>
<style lang="scss">
.cadastro-debito {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #c0564b;
    color: white;
    cursor: pointer;
    .img {
      color: white !important;
    }
    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
