<template>
  <div>
    <vs-button
      icon="search"
      type="line"
      @click="openModal()"
      color="#3da4fd"
      size="small"
    >
      <b style="font-size: 16px">Filtrar</b>
    </vs-button>
    <vs-popup v-if="open" title="Filtrar" :active.sync="modalFiltro">
      <div class="w-full">
        <div id="filtroSelectsDC" class="vs-con-loading__container">
          <vs-row
            vs-type="flex"
            class="mb-2"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="4"
            >
              <div class="w-full">
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Empresa</label
                >
                <br />
                <el-select
                  filterable
                  clearable
                  placeholder="Empresa"
                  v-model="filtro.id_empresa"
                  :popper-append-to-body="false"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="empresa in empresas"
                    :value="empresa.id"
                    :label="empresa.razao_social"
                    :key="empresa.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="4"
            >
              <div class="w-full">
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Plano Conta</label
                >
                <br />
                <el-select
                  filterable
                  clearable
                  remote
                  :remote-method="filtroPlanoConta"
                  :loading="loading"
                  loading-text="Buscando..."
                  :popper-append-to-body="false"
                  placeholder="Pesquise"
                  v-model="filtro.id_plano_conta"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="data in plano_conta"
                    :value="data.id"
                    :label="data.nome"
                    :key="data.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="4"
            >
              <div class="w-full">
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Portador</label
                >
                <br />
                <el-select
                  filterable
                  clearable
                  placeholder="Portador"
                  :popper-append-to-body="false"
                  v-model="filtro.id_portador"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="data in portadores"
                    :value="data.id"
                    :label="data.nome"
                    :key="data.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
          <vs-row
            vs-type="flex"
            class="mb-2"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="4"
            >
              <div class="w-full">
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Tipo da Conta</label
                >
                <br />
                <el-select
                  filterable
                  clearable
                  :popper-append-to-body="false"
                  v-model="filtro.tipo"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="tipo in tipos"
                    :value="tipo.id"
                    :label="tipo.nome"
                    :key="tipo.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="4"
            >
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Tipo Pessoa</label
                >
                <br />
                <el-select
                  filterable
                  clearable
                  name="status"
                  :popper-append-to-body="false"
                  v-model="filtro.tipo_pessoa"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="tipo in tipo_pessoa"
                    :value="tipo.value"
                    :label="tipo.nome"
                    :key="tipo.value"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-checkbox color="success" v-model="filtro.ajuste_caixa">
                Ajuste de Caixa
              </vs-checkbox>
            </vs-col>
          </vs-row>
        </div>
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="mx-2"
              type="date"
              style="width: 300px"
              label="Data De"
              v-model="filtro.dataDe"
            ></vs-input>
            <vs-input
              color="dark"
              class="mx-2"
              type="date"
              style="width: 300px"
              label="Data Até"
              v-model="filtro.dataAte"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="mx-1"
              style="width: 300px"
              type="text"
              v-currency="$currencyFormatterOptions"
              placeholder="R$ 0,00"
              label="Valor De"
              v-model="filtro.valorDe"
            ></vs-input>
            <vs-input
              color="dark"
              class="mx-1"
              style="width: 300px"
              type="text"
              v-currency="$currencyFormatterOptions"
              placeholder="R$ 0,00"
              label="Valor Até"
              v-model="filtro.valorAte"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="mx-1 w-fulll"
              style="width: 600px"
              type="text"
              label="Observação"
              v-model="filtro.obs"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="6"
          >
            <vs-button
              @click="Filtrar()"
              class="w-full mt-3 mx-2"
              color="success"
              type="relief"
              >Filtrar</vs-button
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="6"
          >
            <vs-button
              @click="(limpar = true), Filtrar()"
              class="w-full mt-3 mx-2"
              color="danger"
              type="relief"
              >Filtrar e Limpar</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  data () {
    return {
      tipo_pessoa: [
        { value: 1, nome: "PESSOA FÍSICA" },
        { value: 2, nome: "PESSOA JURÍDICA" }
      ],
      empresas: {},
      plano_conta: {},
      portadores: {},
      tipos: [
        { id: 1, nome: "Débito" },
        { id: 2, nome: "Crédito" }
      ],
      modalFiltro: false,
      filtro: {},
      limpar: false,
      loading: false,
      open: false
    }
  },
  methods: {
    async openModal () {
      this.open = await true
      this.modalFiltro = await true
      await this.$vs.loading({ container: "#filtroSelectsDC", scale: 0.6 })
      try {
        this.filtro.dataHoje = this.$formartData.dataFiltro()
        await this.getEmpresas()
        await this.getPortadores()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#filtroSelectsDC > .con-vs-loading")
      }
    },
    async replaceDots (value) {
      if (value && value.search(",") !== -1) {
        value = value.replace(/\./g, "")
        value = value.replace(/,/g, ".")
      }
      return value
    },
    async Filtrar () {
      this.filtro.valorDe = await this.replaceDots(this.filtro.valorDe)
      this.filtro.valorAte = await this.replaceDots(this.filtro.valorAte)

      this.$emit("filtro", this.filtro)
      this.modalFiltro = await false
      if (this.limpar) {
        this.filtro = await {}
      }
    },
    async getPlanoContaByQuery (query) {
      try {
        this.plano_conta = await this.$http.post(`getPlanoContaByQuery`, {
          query: query
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async filtroPlanoConta (query) {
      if (query.length > 2) {
        this.loading = true
        try {
          await this.getPlanoContaByQuery(query)
        } catch (err) {
          const error = this.$httpErrors(err)
          this.$vs.notify(error)
        } finally {
          this.loading = false
        }
      }
    },
    async getPortadores () {
      try {
        this.portadores = await this.$http.get(`portador`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getEmpresas () {
      try {
        this.empresas = await this.$http.get(`empresa`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    }
  },
  components: {
    ...components
  }
};
</script>
