<template>
  <div>
    <vs-popup
      v-if="open"
      title="Ajuste de Saldo Portador"
      :active.sync="modalAjustarSaldoPortador"
    >
      <div
        class="vs-con-loading__container"
        id="loadingCadastro"
        style="overflow: auto"
      >
        <div class="vs-con-loading__container" id="ajustarSaldoPortador">
          <vs-row
            class="mb-2 mt-1"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="6"
            >
              <div class="w-full mx-1">
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-2"
                  >Empresa</label
                >
                <br />
                <el-select
                  filterable
                  name="empresa"
                  clearable
                  v-validate="'required'"
                  v-model="ajuste.id_empresa"
                  :popper-append-to-body="false"
                  class="w-full"
                >
                  <el-option
                    v-for="empresa in empresas"
                    :value="empresa.id"
                    :label="empresa.razao_social"
                    :key="empresa.id"
                  ></el-option>
                </el-select>
                <span
                  class="text-danger text-sm ml-1"
                  v-show="errors.has('empresa')"
                >
                  {{ $validators.empty }}</span
                >
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="6"
            >
            </vs-col>
          </vs-row>
          <vs-row
            class="mb-2 mt-1"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="6"
            >
              <div class="w-full mx-1">
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-2"
                  >Portador De</label
                ><br />
                <el-select
                  filterable
                  clearable
                  v-model="ajuste.portadorDe"
                  @change="
                    ajuste.portadorDe && ajuste.portadorPara ? montarObs() : ''
                  "
                  v-validate="'required'"
                  name="portadorDe"
                  :popper-append-to-body="false"
                  class="w-full"
                >
                  <el-option
                    v-for="portador in portadores"
                    :value="portador.id"
                    :label="portador.nome"
                    :disabled="portador.id == ajuste.portadorPara"
                    :key="portador.id"
                  ></el-option>
                </el-select>
                <span
                  class="text-danger text-sm ml-1"
                  v-show="errors.has('portadorDe')"
                >
                  {{ $validators.empty }}</span
                >
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="6"
            >
              <div class="w-full mx-1">
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-2"
                  >Portador Para</label
                ><br />
                <el-select
                  filterable
                  clearable
                  v-model="ajuste.portadorPara"
                  name="portadorPara"
                  @change="
                    ajuste.portadorDe && ajuste.portadorPara ? montarObs() : ''
                  "
                  v-validate="'required'"
                  :popper-append-to-body="false"
                  class="w-full"
                >
                  <el-option
                    v-for="portador in portadores"
                    :value="portador.id"
                    :label="portador.nome"
                    :disabled="portador.id == ajuste.portadorDe"
                    :key="portador.id"
                  ></el-option>
                </el-select>
                <span
                  class="text-danger text-sm ml-1"
                  v-show="errors.has('portadorPara')"
                >
                  {{ $validators.empty }}</span
                >
              </div>
            </vs-col>
          </vs-row>
        </div>
        <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <div class="w-full mx-1">
              <vs-input
                class="w-full"
                type="date"
                label="Data"
                color="dark"
                name="data_cadastro"
                v-validate="'required'"
                v-model="ajuste.data"
              ></vs-input>
              <span
                class="text-danger text-sm ml-2"
                v-show="errors.has('data_cadastro')"
              >
                {{ $validators.empty }}</span
              >
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
            <div class="w-full mx-1">
              <vs-input
                color="dark"
                class="w-full"
                name="valor"
                placeholder="R$ 0,00"
                v-currency="$currencyFormatterOptions"
                label="Valor"
                v-model="ajuste.valor"
                type="text"
              ></vs-input>
              <span
                class="text-danger mx-2"
                style="font-size: 11px"
                v-show="errors.has('valor')"
                >{{ $validators.empty }}</span
              >
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2 mt-1"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="w-full mx-1"
              type="text"
              label="Observação"
              v-model="ajuste.obs"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              class="w-full mt-3 mx-1"
              :disabled="blockButton"
              color="success"
              icon="add"
              @click="submitForm()"
              type="relief"
              >Transferir</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  data () {
    return {
      open: false,
      modalAjustarSaldoPortador: false,
      ajuste: {
        portadorDe: null,
        portadorPara: null,
        valor: 0,
        data: this.$formartData.dataFiltro(),
        obs: ""
      },
      portadores: [],
      empresas: [],
      blockButton: true
    }
  },
  methods: {
    async openModal () {
      this.open = true
      try {
        this.modalAjustarSaldoPortador = true
        await this.getPortadores()
        await this.getEmpresas()
        this.blockButton = false
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async submitForm () {
      console.log("UE")
      this.$validator.validateAll().then(result => {
        if (result) {
          this.ajustarSaldos()
        } else {
          this.error = true
          this.$vs.notify(this.$notify.Empty)
        }
      })
    },
    async ajustarSaldos () {
      this.blockButton = true
      await this.$vs.loading({
        container: "#ajustarSaldoPortador",
        scale: 0.6
      })
      try {
        let valor = "" + this.ajuste.valor
        if (valor && valor.search(",") !== -1) {
          valor = valor.replace(/\./g, "")
          valor = valor.replace(/,/g, ".")
        }
        this.ajuste.valor = valor
        await this.$http.post("ajustarSaldoPortador", this.ajuste)
        this.modalAjustarSaldoPortador = false
        this.blockButton = false
        this.$emit("atualizarSaldos")
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#ajustarSaldoPortador > .con-vs-loading")
      }
    },
    async getPortadores () {
      try {
        this.portadores = await this.$http.get(`portador`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getEmpresas () {
      try {
        this.empresas = await this.$http.get(`empresa`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async montarObs () {
      const portadorDe = this.portadores.find(item => item.id == this.ajuste.portadorDe)
      const portadorPara = this.portadores.find(item => item.id == this.ajuste.portadorPara)
      this.ajuste.obs = `Transferência ${portadorDe.nome} para ${portadorPara.nome}`
    }
  },
  async mounted () {
    await this.openModal()
  },
  components: {
    ...components
  }
};
</script>
