<template>
  <vs-button
    icon="add"
    @click="prepareObjtsExcel()"
    color="success"
    type="line"
    size="small"
  >
    <b style="font-size: 16px">Excel</b>
  </vs-button>
</template>

<script>
import XLSX from "xlsx/xlsx"
window.$ = window.jQuery = require("jquery")

export default {
  name: "vue-excel-xlsx",

  props: {
    contas: null,
    portadores: null
  },
  data () {
    return {
      dataContas: [],
      dataPortadores: [],
      columnsContas: [],
      columnsPortadores: [],
      filename: "",
      sheetname: ""
    }
  },

  methods: {
    async exportExcel () {
      let createXLSLFormatObj = [],
        titulo = [],
        newXlsHeader = []
      for (let i in this.columnsContas) {
        newXlsHeader.push(this.columnsContas[i].label)
      }
      titulo.push(null, null, null, "CONTAS")
      titulo.push(null)
      createXLSLFormatObj.push(titulo)
      createXLSLFormatObj.push(newXlsHeader)
      // eslint-disable-next-line no-unused-vars
      for (let [index, dataConta] of this.dataContas.entries()) {
        let innerRowData = []
        for (let k in this.columnsContas) {
          let val = this.columnsContas[k]
          innerRowData.push(dataConta[val.field])
        }
        createXLSLFormatObj.push(innerRowData)
      }
      createXLSLFormatObj.push(null)

      titulo = []
      newXlsHeader = []
      for (let i in this.columnsPortadores) {
        newXlsHeader.push(this.columnsPortadores[i].label)
      }
      titulo.push(null, null, null, "PORTADORES")
      createXLSLFormatObj.push(titulo)
      createXLSLFormatObj.push(newXlsHeader)
      // eslint-disable-next-line no-unused-vars
      for (let [index, dataPortador] of this.dataPortadores.entries()) {
        let innerRowData = []
        for (let k in this.columnsPortadores) {
          let val = this.columnsPortadores[k]
          innerRowData.push(dataPortador[val.field])
        }
        createXLSLFormatObj.push(innerRowData)
      }

      let filename = this.filename + ".xlsx"

      let ws_name = this.sheetname

      let wb = XLSX.utils.book_new()
      let ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj)
      XLSX.utils.book_append_sheet(wb, ws, ws_name)
      XLSX.writeFile(wb, filename)
    },
    async prepareObjtsExcel () {
      this.dataContas = []
      this.dataPortadores = []
      this.filename = `CAIXA`
      this.sheetname = "sheetname"
      this.columnsContas = [
        {
          label: "N°",
          field: "numero"
        },
        {
          label: "Data Emissão",
          field: "data_emissao"
        },
        {
          label: "Cliente",
          field: "cliente"
        },
        {
          label: "Fornecedor",
          field: "fornecedor"
        },
        {
          label: "Portador",
          field: "portador"
        },
        {
          label: "Valor Pago",
          field: "valor_pago"
        },
        {
          label: "Plano Conta",
          field: "plano_conta"
        }
      ]
      this.columnsPortadores = [
        {
          label: "",
          field: null
        },
        {
          label: "",
          field: null
        },
        {
          label: "Nome",
          field: "nome"
        },
        {
          label: "Saldo Inicial",
          field: "saldo_inicial"
        },
        {
          label: "Saldo Atual",
          field: "saldo_atual"
        }
      ]
      try {
        this.dataContas = []
        this.dataPortadores = []
        // eslint-disable-next-line no-unused-vars
        for (let [index, conta] of this.contas.entries()) {
          this.dataContas.push({
            numero: conta.id,
            data_emissao: await this.$formartData.formatar(
              new Date(conta.data),
              2
            ),
            cliente: conta.cliente,
            fornecedor: conta.fornecedor,
            portador: conta.portador,
            valor_pago: conta.valor,
            plano_conta: conta.plano_conta
          })
        }
        // eslint-disable-next-line no-unused-vars
        for (let [index, portador] of this.portadores.entries()) {
          this.dataPortadores.push({
            nome: portador.nome,
            saldo_inicial: portador.saldo_inicial,
            saldo_atual: portador.saldo_atual
          })
        }
        await this.exportExcel()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    }
  }
};
</script>
